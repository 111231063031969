import React from 'react'
import {CursosEdit} from '../components/shared/Edit/CursosEdit'

export default function Editar() {
  return (
   
    <>
    <main>
        <CursosEdit/>
    </main>
    </>
  )
}
