import axios from 'axios';

export const callApiMainArticle = async (page = 1, limit = 5, searchParams = {}) => {
  const paramsSearch = Object.keys(searchParams)?.reduce((acc, key) => {
    const label = key;
    const value = searchParams[key];
    return acc + `&${label}=${value}`;
  }, '');

  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://backend.academit.com.co/main-article?page=${page}&limit=${limit}${paramsSearch}`,
    headers: {
      Accept: 'application/json',
    },
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    return {
      type: 'unsucces',
      message: error.response.data.message
    }
  }
};

export const updateItemMainArticle = async (id, data) => {

  console.log('esta es la data', data)

  const formdata = new FormData();
  console.log(FormData);
  formdata.append("titulo", data['Título']);
  formdata.append("descripcion", data['Descripción']);
  formdata.append("textoBoton", data['Botón']);
  formdata.append("navegacionBoton", data['navegacion']);
  formdata.append("archivoImagen", data['Imagen']);

  const config = {
    method: 'patch',
    url: `https://backend.academit.com.co/main-article/${id}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formdata,
  };
  const response = await axios.request(config);
  return response.data;
};

export const deleteItemMainArticle = async (id) => {
  console.log(id);
  const config = {
    method: 'delete',
    url: `https://backend.academit.com.co/main-article/${id}`,
  };
  const response = await axios.request(config);
  return response.data;
};

export const createItemMainArticle = async (formValues) => {

  const myMainArticles = new Headers();

  console.table(formValues);
  const formdata = new FormData();
  formdata.append("titulo", formValues['Título']);
  formdata.append("descripcion", formValues['Descripción']);
  formdata.append("textoBoton", formValues['Botón']);
  formdata.append("navegacionBoton", formValues['navegacion']);
  formdata.append("archivoImagen", formValues['Imagen']);

  const requestOptions = {
    method: "POST",
    headers: myMainArticles,
    body: formdata,
    redirect: "follow"
  };

  const datos = await fetch("https://backend.academit.com.co/main-article", requestOptions);

  console.log(datos);
  return datos
};